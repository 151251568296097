const Refund = () => {
  return (
    <div className="policy">
      <h1>Refund Policy</h1>
      <h3>RETURN </h3>
      <p>
        The Company disclaims all liability and confirms that it will not, under
        all conditions, entertain any appeal for a return of any request made on
        www.easyhiring.in
      </p>
      <h3>EXCHANGE</h3>
      <p>
        The operation or method of exchanging the Company’s services for any
        other services rendered by the Company is referred to as ‘Exchange.’
        After putting an order for a course on the website, but before accessing
        and availing the course, the User may request an Exchange of Course with
        another course accessible on the platform. The Company will provide an
        intimation to the User requesting either “Exchange” or “Replacement,”
        and the User will receive clarification of the replacement.
      </p>
      <h3>POINTS TO BE NOTED</h3>
      <p>
        The User can request for an exchange before accessing the course online
        on the platform of the User. If the Company disagrees an exchange
        request due to non-technical issue, the User can file a dispute. We
        encourage the User to review the course and its subjective nature before
        making the request to avail such services. The User needs to raise the
        exchange request within 7 days from availing the course online.
      </p>
      <h3>REFUND PROCESS</h3>
      <p>
        For a refund request, the User may submit a request on the Company’s
        website, and a Company representative will contact the User at the
        registered phone number given by the User on the Platform. The Refund
        request shall be entertained and processed if the Company has failed to
        provide the course availed for by the User within 7 days of course
        starting date. In no other circumstance, the Company shall process a
        refund request. The Company stands to be at the sole discretion to
        refund to the User and all the refunds shall be processed after such
        statutory deductions. All the refunds shall be credited to the source of
        the payment received by the Company from the User. In certain
        situations, the refunds shall reflect in the payment source in 3-5
        working days and the Company disclaims all liabilities for the same.
      </p>
      <div class="empty"></div>
    </div>
  );
};

export default Refund;
