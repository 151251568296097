import { Alert, Box, Card, Typography } from "@mui/material";
import { blue, blueGrey, teal } from "@mui/material/colors";
import * as React from "react";
import withAuth from "../../hoc/withAuth";
import { PAYMENT_STATUS } from "../../utils/constants";

const allSites = {
  material: {
    title: "Study Material",
    description: "Learn | Practice",
    link: process.env.REACT_APP_SM_WEB,
    background: blue["100"],
    backgroundStart: blue["100"],
    backgroundEnd: blue["900"],
    foreground: "#000",
  },
  test: {
    title: "Test",
    description: "Take Test | View Report",
    link: process.env.REACT_APP_TEST_WEB,
    background: teal["400"],
    backgroundStart: teal["100"],
    backgroundEnd: teal["900"],
    foreground: "#fff",
  },
  path: {
    title: "Path",
    description: "Jobs | Hiring",
    link: process.env.REACT_APP_PATH_WEB,
    background: blueGrey["700"],
    backgroundStart: blueGrey["100"],
    backgroundEnd: blueGrey["900"],
    foreground: "#fff",
  },
  collegeAdmin: {
    title: "Admin Dashboard",
    description: "Assess | Manage",
    link: process.env.REACT_APP_ADMIN_WEB,
    background: blueGrey["700"],
    backgroundStart: blueGrey["100"],
    backgroundEnd: blueGrey["900"],
    foreground: "#fff",
  },
};

const studentSites = [allSites.material, allSites.test, allSites.path];
const adminSites = [allSites.collegeAdmin, allSites.material, allSites.test];

const Home = ({ isAdmin }) => {
  const sites = isAdmin ? adminSites : studentSites;

  const query = new URLSearchParams(window.location.search);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        width: "100%",
      })}
    >
      {query.get("payment") === PAYMENT_STATUS.SUCCESS && (
        <Alert
          severity="success"
          sx={{ width: "100%", justifyContent: "center" }}
        >
          Payment Successful! Start learning by click on "Study Material" below.
        </Alert>
      )}
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          width: "90%",
          pt: 4,
          pb: 0,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            p: 0,
            m: 0,
            width: "100%",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            flex: 2,
            display: "flex",
            flexDirection: "column",
            pt: 20,
            [theme.breakpoints.down("md")]: {
              p: 2,
              m: 1,
              flex: 0,
            },
          })}
        >
          <Typography
            variant="h2"
            gutterBottom
            color="inherit"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                fontSize: "2rem",
              },
            })}
          >
            Learn from the best and get your dream job.
          </Typography>
          <Typography
            variant="h5"
            fontWeight={400}
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                fontSize: "1.2rem",
                fontWeight: 100,
              },
            })}
          >
            Online courses from the best instructors
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pl: 20,
            [theme.breakpoints.down("md")]: {
              p: 0,
              m: 1,
              mt: 0,
              alignItems: "center",
              flex: 0,
            },
          })}
        >
          <Typography
            variant="h3"
            sx={(theme) => ({
              ml: 1,
              [theme.breakpoints.down("md")]: {
                m: 1,
                ml: 2,
                fontSize: "1.5rem",
                alignSelf: "flex-start",
              },
            })}
            gutterBottom
          >
            Apps
          </Typography>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexWrap: "wrap",
              flex: 1,
              [theme.breakpoints.down("md")]: {
                justifyContent: "center",
                alignItems: "center",
              },
            })}
          >
            {sites.map((site) => (
              <Card
                key={site.link}
                sx={(theme) => ({
                  m: 1,
                  padding: 3,
                  height: 120,
                  width: 180,
                  cursor: "pointer",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: site.background,
                  // backgroundImage: `linear-gradient(to bottom right, ${site.backgroundStart}, ${site.backgroundEnd})`,
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                  },
                })}
                onClick={() => (window.location = site.link)}
              >
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ color: site.foreground }}
                >
                  {site.title}
                </Typography>
                <Typography sx={{ mb: 1.5, color: site.foreground }}>
                  {site.description}
                </Typography>
              </Card>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withAuth(Home);
