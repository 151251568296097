import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import { registerReq } from "../../api/login";
import RegisterForm from "../../components/RegisterForm";

export default function RegisterPage() {
  const onSubmit = async (data) => {
    await registerReq(data);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `linear-gradient(to bottom, ${grey[100]}, ${grey[300]})`,
          filter: "blur(10px)",
          position: "relative",
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      >
        <RegisterForm onSubmit={onSubmit} />
      </Box>
    </>
  );
}
