const styles = {
  container: (theme) => ({
    width: 500,
    height: "auto",
    flexDirection: "column",
    display: "flex",
    margin: "10px",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      padding: "10px",
    },
  }),
  title: (theme) => ({
    margin: "auto",
    color: "#444",
    marginBottom: 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  }),
  errorAlert: {
    marginBottom: 2,
  },
  formItem: {
    marginBottom: "20px",
    backgroundColor: "#f8f8f8",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "20px",
  },
  submitButton: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#222",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#666",
    },
  },
  otpButton: {
    marginRight: "auto",
  },
};

export default styles;
