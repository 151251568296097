import React from "react";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "./Logo";
import Account from "./Account";

export const MenuButton = ({ onClick }) => {
  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: 2 }}
      onClick={onClick}
    >
      <MenuIcon />
    </IconButton>
  );
};

const NavBar = React.forwardRef(({ toggleDrawer, disabledView }, ref) => {
  return (
    <Box ref={ref}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          {/* <MenuButton onClick={toggleDrawer} /> */}
          <Logo disableClick={disabledView} />
          {!disabledView && <Account />}
        </Toolbar>
      </AppBar>
    </Box>
  );
});

export default NavBar;
