export const USER_ROLE = {
  admin: "admin",
  student: "student",
  superadmin: "superadmin",
  staffadmin: "staffadmin",
  companyadmin: "company_admin",
};

export const PAYMENT_STATUS = {
  INITIATED: "initiated",
  PROCESSING: "processing",
  UNKNOWN: "UNKNOWN",
  SUCCESS: "success",
  FAILED: "failed",
};
