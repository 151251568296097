import { createContext, useEffect, useReducer } from "react";
import "./App.css";
import Layout from "./layout";
import { getUserDetails } from "./api/user";

export const AuthContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchUserDetails = (ignore = true) => {
    // `ignore` is to ignore redirection
    getUserDetails(ignore).then((res) => {
      dispatch({
        type: "LOGIN",
        payload: {
          user: res.user,
        },
      });
    });
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        fetchUserDetails,
      }}
    >
      <Layout />
    </AuthContext.Provider>
  );
}

export default App;
