import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import LoginForm from "../../components/LoginForm";
import { loginReq } from "../../api/login";

export default function LoginPage() {
  const onSubmit = async (username, password) => {
    await loginReq(username, password);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: "url('login-bg.jpeg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          // filter: "blur(2px)",
          position: "relative",
        }}
      ></Box>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // backgroundColor: "#e5e5e5fa",
          backgroundColor: "#fff",
        }}
        // elevation={24}
        variant="outlined"
      >
        <LoginForm onSubmit={onSubmit} />
      </Paper>
    </>
  );
}
