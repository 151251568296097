const styles = {
  container: (theme) => ({
    width: "auto",
    maxWidth: "500px",
    flexDirection: "column",
    display: "flex",
    margin: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "20px",
  }),
  title: {
    marginLeft: "auto",
    marginRight: "auto",
    color: "#444",
  },
  requiredInfo: {
    marginLeft: "auto",
  },
  formItem: {
    marginBottom: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    mt: 2,
  },
  submitButton: {
    mr: 1,
  },
  otpButton: {
    marginRight: "auto",
  },
  well: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ddd",
    padding: "20px",
    borderRadius: "5px",
    marginTop: "20px",
    marginBottom: "20px",
  },
};

export default styles;
