import { delReq, getReq, postReq, putReq, uploadToBucket } from ".";

export const getUserDetails = (ignore) => {
  return getReq(`/me`, {}, true, ignore);
};

export const updateUserDetails = (data) => {
  return putReq(`/me`, data, true);
};

export const updateEducationDetails = (data) => {
  return putReq(`/me/education`, data, true);
};

export const updateExperienceDetails = (data) => {
  return putReq(`/me/experience`, data, true);
};

export const updateProjectDetails = (data) => {
  return putReq(`/me/projects`, data, true);
};

export const changeUserAvatar = async (avatar) => {
  const res = await postReq(`/avatar`, { fileType: avatar.type }, true);
  await uploadToBucket(res.url, avatar, avatar.type);
};

export const removeUserAvatar = async () => {
  await delReq(`/avatar`, {}, true);
};

export const changeUsername = (mobile, email) => {
  return putReq(`/username`, { mobile, email }, true);
};

export const getBranches = () => {
  return getReq(`/branch`);
};
