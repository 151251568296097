import { LoadingButton } from "@mui/lab";
import { Alert, FormControl, Link, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import routes from "../../layout/routes.js";
import { validateEmail, validateMobile } from "../../utils/validations.js";
import { PasswordInput } from "../utils/inputs.js";
import styles from "./styles.js";

export default function LoginForm({ onSubmit }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: null });
  const [loading, setLoading] = useState(false);

  const onSubmitClick = async () => {
    setErrors({});
    if (!validateEmail(username) && !validateMobile(username)) {
      setErrors({
        username: "Invalid username. Please enter an email or mobile number",
      });
      return;
    }
    setLoading(true);
    try {
      await onSubmit(username, password);
    } catch (err) {
      setErrors({ message: err.response.data?.message || "Login failed" });
    } finally {
      setLoading(false);
    }
  };

  const onUsernameChange = (e) => {
    const { value } = e.target;
    setUsername(value);
    setErrors({ ...errors, username: null, message: null });
  };
  const onPasswordChange = (value) => {
    setPassword(value);
    setErrors({ ...errors, message: null });
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h4" gutterBottom component="div" sx={styles.title}>
        Login
      </Typography>
      {errors.message && (
        <Alert sx={styles.errorAlert} severity="error">
          {errors.message}
        </Alert>
      )}

      <FormControl sx={styles.formItem}>
        <TextField
          id="login-username"
          value={username}
          onChange={onUsernameChange}
          label="E-mail / Mobile"
          required
          error={Boolean(errors.username)}
          helperText={errors.username}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmitClick();
            }
          }}
        />
      </FormControl>

      <FormControl sx={styles.formItem}>
        <PasswordInput
          password={password}
          onChange={onPasswordChange}
          onEnter={onSubmitClick}
        />
      </FormControl>

      <Link href={routes.resetPassword.path}>Forgot password?</Link>

      <Box sx={styles.buttonContainer}>
        <LoadingButton
          loading={loading}
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmitClick}
          sx={styles.submitButton}
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
}
