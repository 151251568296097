import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, Chip, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { applyCoupon, createOrder } from "../../api/plans";

const COUPON_STATES = {
  button: "button",
  input: "input",
  success: "success",
};

export default function PlanCard({ plan }) {
  const [coupon, setCoupon] = useState("");
  const [paymentError, setPaymentError] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [couponState, setCouponState] = useState(COUPON_STATES.button);
  const [discountedAmount, setDiscountedAmount] = useState(plan.amount);
  const [loading, setLoading] = useState(false);
  const [loadingCoupon, setLoadingCoupon] = useState(false);

  const showCouponInput = () => {
    setCouponState(COUPON_STATES.input);
  };
  const checkCouponIsValid = async () => {
    setLoadingCoupon(true);
    try {
      const res = await applyCoupon({
        planId: plan._id,
        coupon,
      });

      setCouponState(COUPON_STATES.success);
      setDiscountedAmount(res.discountedAmount);
    } catch (err) {
      console.error(err.response.data);
      setCouponError(err.response?.data?.message || "Something went wrong");
    } finally {
      setLoadingCoupon(false);
    }
  };
  const goToPaymentPage = async () => {
    setPaymentError(false);
    setLoading(true);
    try {
      const data = await createOrder({ planId: plan._id, couponCode: coupon });

      // redirect payment url
      window.location = data.url;
    } catch (err) {
      console.error(err.response.data);
      setPaymentError(true);
    } finally {
      setLoading(false);
    }
  };
  const removeCoupon = () => {
    setCoupon("");
    setCouponState(COUPON_STATES.button);
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        p: 2,
      }}
    >
      <Box>
        <Alert
          severity="error"
          sx={{ display: paymentError ? "flex" : "none" }}
        >
          Something went wrong, Please try again.
          <br />
          If the problem persists, contact support.
        </Alert>
        <Typography
          variant="button"
          component="div"
          sx={{ fontSize: 24 }}
          gutterBottom
        >
          {plan.name}
        </Typography>

        <Typography sx={{ mb: 1.5 }}>
          Validity: {plan.intervalCount}{" "}
          {plan.intervalCount < 2 ? plan.unit : plan.unit + "s"}
        </Typography>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {plan.description}
        </Typography>
      </Box>
      <Box sx={{ marginTop: "auto", display: "flex", mb: 1 }}>
        <Typography
          variant="h6"
          fontWeight={500}
          color="black"
          sx={
            plan.discountedAmount
              ? {
                  textDecoration: "line-through",
                  color: "grey",
                  fontSize: "1rem",
                  mr: 1,
                }
              : {}
          }
        >
          &#8377; {plan.amount}
        </Typography>
        {plan.discountedAmount && (
          <Typography
            sx={{
              mr: 1,
              textDecoration:
                couponState === COUPON_STATES.success && discountedAmount
                  ? "line-through"
                  : null,
            }}
          >
            &#8377; {plan.discountedAmount}
          </Typography>
        )}
        {couponState === COUPON_STATES.success && discountedAmount ? (
          <Typography sx={{ fontWeight: "bold" }}>
            &#8377; {discountedAmount}
          </Typography>
        ) : null}
      </Box>
      <Box>
        {couponState === COUPON_STATES.button ? (
          <Button sx={{ mb: 1.5 }} variant="text" onClick={showCouponInput}>
            Apply coupon
          </Button>
        ) : couponState === COUPON_STATES.input ? (
          <TextField
            sx={{ mb: 1.5 }}
            size="small"
            label="Coupon"
            variant="standard"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value.toUpperCase())}
            InputProps={{
              endAdornment: (
                <LoadingButton
                  loading={loadingCoupon}
                  variant="text"
                  onClick={checkCouponIsValid}
                  disabled={!coupon}
                >
                  Apply
                </LoadingButton>
              ),
            }}
            error={couponError !== ""}
            helperText={couponError}
          />
        ) : couponState === COUPON_STATES.success ? (
          <Chip
            variant="outlined"
            label={`"${coupon}" applied`}
            sx={{ mb: 1.5 }}
            color="success"
            onDelete={removeCoupon}
          />
        ) : null}
      </Box>

      <LoadingButton
        loading={loading}
        variant="contained"
        sx={{
          width: "100%",
        }}
        onClick={goToPaymentPage}
      >
        Buy
      </LoadingButton>
    </Box>
  );
}
