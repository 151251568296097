import { Check } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { USER_ROLE } from "../../utils/constants.js";
import {
  isCompanyEmail,
  validateEmail,
  validateMobile,
  validatePassword,
  validateUrl,
} from "../../utils/validations.js";
import { EmailInput, MobileInput, PasswordInput } from "../utils/inputs.js";
import styles from "./styles.js";

export default function RegisterForm({ onSubmit }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("student");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");

  const [errors, setErrors] = useState({ email: null });
  const [message, setMessage] = useState(null);

  const onSubmitClick = async () => {
    let errors = {};
    if (!name) {
      errors.name = "Name is required";
    }
    if (!validateEmail(email)) {
      errors.email = "Invalid email";
    }
    if (!validateMobile(mobile)) {
      errors.mobile = "Invalid mobile number";
    }
    if (!validatePassword(password)) {
      errors.password = "Password does not meet the requirements";
    }
    if (role === "company_admin") {
      if (!isCompanyEmail(email)) {
        errors.email = "Please use your company email";
      }
      if (!companyName) {
        errors.companyName = "Company name is required";
      }
      if (!validateUrl(companyWebsite)) {
        errors.website = "Invalid URL";
      }
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setLoading(true);
      setMessage(null);
      await onSubmit({
        name,
        email,
        mobile,
        password,
        role,
        company: { name: companyName, website: companyWebsite },
      });
    } catch (err) {
      setMessage(err.response?.data?.message);
      console.log({ err });
      console.log("Registration failed");
    } finally {
      setLoading(false);
    }
  };

  const onNameChange = (e) => {
    const { value } = e.target;
    setName(value);
  };
  const onEmailChange = (e) => {
    const { value } = e.target;
    setErrors({ ...errors, email: null });
    setEmail(value);
  };
  const onMobileChange = (e) => {
    const { value } = e.target;
    setErrors({ ...errors, mobile: null });
    setMobile(value.slice(0, 10));
  };
  const onRoleChange = (e) => {
    const { value } = e.target;
    setErrors({ ...errors, role: null });
    setRole(value);
    if (value === "company_admin" && !isCompanyEmail(email)) {
      setErrors({ ...errors, email: "Please use your company email" });
    }
  };
  const onPasswordChange = (value, meetsConditions) => {
    const newErrors = { ...errors };
    newErrors.confirmPassword =
      confirmPassword && value !== confirmPassword
        ? "Password does not match"
        : null;
    newErrors.password = meetsConditions ? null : "Password is too weak";
    setErrors(newErrors);
    setPassword(value);
  };
  const onConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    if (value && value !== password) {
      setErrors({ ...errors, confirmPassword: "Password does not match" });
    } else {
      setErrors({ ...errors, confirmPassword: null });
    }
  };
  const onCompanyWebsiteChange = (e) => {
    const { value } = e.target;
    setCompanyWebsite(value);
    if (!validateUrl(value)) {
      setErrors({ ...errors, website: "Invalid URL" });
    }
  };

  return (
    <Paper sx={styles.container}>
      <Typography variant="h4" component="div" sx={styles.title}>
        Register
      </Typography>

      {message ? (
        <Alert severity="error">{message}</Alert>
      ) : (
        <Box sx={{ height: 48 }} />
      )}

      <Typography
        variant="caption"
        gutterBottom
        component="div"
        sx={styles.requiredInfo}
      >
        * indicates the field is required
      </Typography>

      <Box sx={styles.form}>
        <FormControl sx={styles.formItem}>
          <TextField
            id="name"
            value={name}
            onChange={onNameChange}
            label="Name"
            type="text"
            required
            error={Boolean(errors.name)}
            helperText={errors.name}
          />
        </FormControl>

        <FormControl sx={styles.formItem}>
          <EmailInput
            email={email}
            onChange={onEmailChange}
            error={errors.email}
          />
        </FormControl>

        <FormControl sx={styles.formItem}>
          <MobileInput
            mobile={mobile}
            onChange={onMobileChange}
            error={errors.mobile}
          />
        </FormControl>

        <FormControl sx={styles.formItem}>
          <PasswordInput
            password={password}
            onChange={onPasswordChange}
            showConditions
          />
        </FormControl>

        <FormControl sx={styles.formItem}>
          <PasswordInput
            label="Confirm Password"
            password={confirmPassword}
            onChange={onConfirmPasswordChange}
            helperText={errors.confirmPassword}
            error={Boolean(errors.confirmPassword)}
          />
        </FormControl>

        <FormControl sx={styles.well}>
          <FormLabel>I am looking to</FormLabel>
          <RadioGroup value={role} onChange={onRoleChange}>
            <FormControlLabel
              value={USER_ROLE.student}
              control={<Radio />}
              label="Learn and find a job"
            />
            <FormControlLabel
              value={USER_ROLE.companyadmin}
              control={<Radio />}
              label="Hire candidates"
            />
          </RadioGroup>
        </FormControl>

        {role === USER_ROLE.companyadmin && (
          <Box sx={styles.well}>
            <Typography variant="h6" component="div">
              Company Details
            </Typography>
            <FormControl sx={styles.formItem}>
              <TextField
                id="company-name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                label="Name"
                type="text"
                required
                error={Boolean(errors.companyName)}
                helperText={errors.companyName}
              />
            </FormControl>
            <FormControl sx={styles.formItem}>
              <TextField
                id="company-website"
                value={companyWebsite}
                onChange={onCompanyWebsiteChange}
                label="Website"
                type="text"
                required
                error={Boolean(errors.website)}
                helperText={errors.website}
              />
            </FormControl>
          </Box>
        )}

        <Box sx={styles.buttonContainer}>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={onSubmitClick}
            sx={styles.submitButton}
            startIcon={loading ? <CircularProgress size={20} /> : <Check />}
          >
            Register
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
}
