import { Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logoutReq } from "../../api/login";
import withAuth from "../../hoc/withAuth";
import routes from "../../layout/routes";

const Logout = ({ dispatch }) => {
  let navigate = useNavigate();

  const logout = async () => {
    try {
      await logoutReq();
    } finally {
      try {
        await dispatch({ type: "LOGOUT" });
      } finally {
        navigate(routes.home.path);
      }
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <Box
      sx={{
        height: "50vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Logging you out ...
    </Box>
  );
};;
export default withAuth(Logout);
