const Return = () => {
  return (
    <div className="policy">
      <h1>Return Policy</h1>
      <h3>RETURN </h3>
      <p>
        The Company disclaims all liability and confirms that it will not, under
        all conditions, entertain any appeal for a return of any request made on
        www.easyhiring.in
      </p>
      <h3>EXCHANGE</h3>
      <p>
        The operation or method of exchanging the Company’s services for any
        other services rendered by the Company is referred to as ‘Exchange.’
        After putting an order for a course on the website, but before accessing
        and availing the course, the User may request an Exchange of Course with
        another course accessible on the platform. The Company will provide an
        intimation to the User requesting either “Exchange” or “Replacement,”
        and the User will receive clarification of the replacement.
      </p>
      <h3>POINTS TO BE NOTED</h3>
      <p>
        The User can request for an exchange before accessing the course online
        on the platform of the User. If the Company disagrees an exchange
        request due to non-technical issue, the User can file a dispute. We
        encourage the User to review the course and its subjective nature before
        making the request to avail such services. The User needs to raise the
        exchange request within 7 days from availing the course online.
      </p>
      <div class="empty"></div>
    </div>
  );
};

export default Return;
