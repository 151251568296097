export const MAX_OTP_ATTEMPTS = 2;

export const FEATURES = [
  {
    name: "Job postings",
    description: "Up-to-date job postings from companies",
    value: "JOB_POSTINGS",
  },
  {
    name: "View text content",
    description: "You can read articles, cheatsheets, formula sheet etc.",
    value: "VIEW_CONTENT",
  },
  {
    name: "Practice tests",
    description: "You can take practice tests for all topics",
    value: "PRACTICE_TESTS",
  },
  {
    name: "Watch videos",
    description: "You can watch videos related to your course",
    value: "WATCH_VIDEOS",
  },
  {
    name: "English training",
    description: "Access to English grammar, tenses and many more",
    value: "ENGLISH_TRAINING",
  },
  {
    name: "English bot",
    description:
      "An assistant to help improve your English skills. Powered by AI.",
    value: "ENGLISH_BOT",
  },
  {
    name: "Doubt support",
    description: "Got doubts while studying? We are here to help you",
    value: "DOUBT_SUPPORT",
  },
  {
    name: "Mock interviews",
    description: "We will conduct mock interviews with industry experts",
    value: "MOCK_INTERVIEWS",
  },
  {
    name: "Placement assistance",
    description: "Everything you require to crack interview",
    value: "PLACEMENT_ASSISTANCE",
  },
];
