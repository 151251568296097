import React, { useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  DeleteOutline as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import withAuth from "../../hoc/withAuth";
import { updateProjectDetails } from "../../api/user";
import Duration from "./Duration";
import Header from "./Header";

const ProjectDetails = ({ projects }) => {
  if (!projects || !projects.length) {
    return (
      <Typography sx={{ p: 2 }} variant="body1">
        No projects added
      </Typography>
    );
  }

  return (
    <List>
      {projects.map((project) => (
        <ListItem key={project._id}>
          <ListItemText primary={project.title} secondary={project.details} />
        </ListItem>
      ))}
    </List>
  );
};

const ProjectsForm = ({ editedProjects, onChange }) => {
  const onValueChange = (index, project) => {
    const newProjects = [...editedProjects];
    newProjects[index] = project;
    onChange(newProjects);
  };

  const addNewProject = () => {
    const newProjects = [...editedProjects, {}];
    onChange(newProjects);
  };
  const removeItem = (index) => {
    const newProjects = [...editedProjects];
    newProjects.splice(index, 1);
    onChange(newProjects);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {editedProjects.map((project, index) => (
        <>
          <Paper
            key={project._id}
            variant="outlined"
            sx={{ display: "flex", flexDirection: "row", p: 2, m: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <TextField
                label={"Title"}
                value={project.title}
                onChange={(e) =>
                  onValueChange(index, { ...project, title: e.target.value })
                }
                sx={{ m: 1, ml: 0, mr: 0 }}
              />
              <TextField
                label={"Details"}
                value={project.details}
                multiline
                rows={4}
                onChange={(e) =>
                  onValueChange(index, {
                    ...project,
                    details: e.target.value,
                  })
                }
                sx={{ m: 1, ml: 0, mr: 0 }}
              />
              <Duration
                startDate={project.startDate}
                endDate={project.endDate}
                onChange={(startDate, endDate) =>
                  onValueChange(index, {
                    ...project,
                    startDate,
                    endDate,
                  })
                }
              />
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                ml: 2,
                mr: 2,
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={() => removeItem(index)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          </Paper>
          <Divider />
        </>
      ))}
      <Button onClick={addNewProject}>Add New</Button>
    </Box>
  );
};

const Projects = ({ user }) => {
  const [editMode, setEditMode] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [editedProjects, setEditedProjects] = React.useState([]);

  useEffect(() => {
    const projects = user?.projects || [];
    setProjects(projects);
    setEditedProjects(projects);
  }, [user]);

  const onSubmit = async () => {
    try {
      await updateProjectDetails(editedProjects);
      setProjects(editedProjects);
      setEditMode(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        m: 1,
      }}
    >
      <Header
        title="Projects"
        editMode={editMode}
        setEditMode={setEditMode}
        onSubmit={onSubmit}
      />
      <Divider />
      {editMode ? (
        <ProjectsForm
          editedProjects={editedProjects}
          onChange={setEditedProjects}
        />
      ) : (
        <ProjectDetails projects={projects} />
      )}
    </Paper>
  );
};

export default withAuth(Projects);
