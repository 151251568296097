import * as React from "react";
import { Box } from "@mui/material";
import NavBar from "./NavBar";
import Router from "./Router";
import { grey } from "@mui/material/colors";
import { useLocation } from "react-router";
import routes from "./routes";

export default function Layout() {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef();

  const location = useLocation();

  React.useEffect(() => {
    setHeight(ref.current?.offsetHeight);
  }, [ref.current?.offsetHeight]);

  return (
    <Box sx={{ height: "100vh" }}>
      <NavBar
        ref={ref}
        disabledView={location.pathname.startsWith(routes.certificate._path)}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          flex: 1,
          height: `calc(100% - ${height}px)`,
          overflow: "scroll",
          // backgroundColor: "rgba(255, 255, 255, .15)",
          backdropFilter: "blur(5px)",
          // background: `linear-gradient(to bottom, #fff, #efefef)`,
          backgroundImage: `linear-gradient(to bottom, ${grey[50]}, ${grey[400]})`,
          backgroundColor: grey[100],
          // [theme.breakpoints.down("md")]: {
          //   height: "auto",
          // },
        })}
      >
        <Router />
      </Box>
    </Box>
  );
}
