import { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import { getBranches, updateUserDetails } from "../../api/user";
import withAuth from "../../hoc/withAuth";
import Header from "./Header";

const AboutUser = ({ user, isAdmin, dispatch }) => {
  const [editedDetails, setEditedDetails] = useState({
    name: user?.name,
    branch: user?.branch?._id,
  });
  const [editMode, setEditMode] = useState(false);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    setEditedDetails({ name: user?.name, branch: user?.branch?._id });
  }, [user]);

  useEffect(() => {
    if (editMode && branches.length === 0) {
      getBranches().then((res) => {
        setBranches(res.branches);
      });
    }
  }, [editMode]);

  const onSubmit = async () => {
    try {
      const res = await updateUserDetails(editedDetails);
      dispatch({
        type: "LOGIN",
        payload: {
          user: res.user,
        },
      });
      setEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        m: 1,
      }}
    >
      <Header
        title="About"
        editMode={editMode}
        setEditMode={setEditMode}
        onSubmit={onSubmit}
      />
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <List>
          <ListItem>
            {editMode ? (
              <TextField
                label={"Name"}
                value={editedDetails?.name}
                onChange={(e) =>
                  setEditedDetails({ ...editedDetails, name: e.target.value })
                }
              />
            ) : (
              <ListItemText primary="Name" secondary={user?.name} />
            )}
          </ListItem>
          <ListItem>
            {editMode && isAdmin ? (
              <TextField
                margin="dense"
                label="Branch"
                select
                value={editedDetails?.branch}
                onChange={(e) =>
                  setEditedDetails({ ...editedDetails, branch: e.target.value })
                }
                // MenuProps={MenuProps}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Branch</em>;
                  }

                  return branches?.find((branch) => branch._id === selected)
                    ?.title;
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch._id} value={branch._id}>
                    {branch.title}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <ListItemText primary="Branch" secondary={user?.branch?.title} />
            )}
          </ListItem>
          <ListItem>
            <ListItemText primary="Email" secondary={user?.email} />
          </ListItem>
        </List>
      </Box>
    </Paper>
  );
};

export default withAuth(AboutUser);
