import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { getPaymentStatus } from "../../api/plans";
import withAuth from "../../hoc/withAuth";
import Loading from "../utils/loading";
import Error from "../utils/error";
import { Box, Typography } from "@mui/material";
import Success from "../utils/success";
import Message from "../utils/message";
import routes from "../../layout/routes";
import { PAYMENT_STATUS } from "../../utils/constants";
import { Warning as WarningIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Payment = ({ user }) => {
  const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUS.INITIATED);

  const query = new URLSearchParams(useLocation().search);
  const transactionId = query.get("trans_id");

  useEffect(() => {
    poll();
  }, []);

  const poll = async () => {
    for (let i = 0; i < 10; i++) {
      console.log(i);
      const response = await getPaymentStatus(transactionId);
      switch (response.status) {
        case "success":
          setPaymentStatus(PAYMENT_STATUS.SUCCESS);
          return;
        case "failed":
          setPaymentStatus(PAYMENT_STATUS.FAILED);
          return;
        case "processing":
          if (i >= 5) {
            setPaymentStatus(PAYMENT_STATUS.PROCESSING);
          } else {
            setPaymentStatus(PAYMENT_STATUS.INITIATED);
          }
          break;
        default:
          setPaymentStatus(PAYMENT_STATUS.UNKNOWN);
          return;
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
    setPaymentStatus(PAYMENT_STATUS.UNKNOWN);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      {paymentStatus === PAYMENT_STATUS.SUCCESS ? (
        <Success
          message="Payment Successfull"
          redirectTo={routes.home.path + `?payment=${paymentStatus}`}
        />
      ) : paymentStatus === PAYMENT_STATUS.FAILED ? (
        <Error
          message="Payment Failed"
          redirectTo={routes.plans.path + `?payment=${paymentStatus}`}
        />
      ) : paymentStatus === PAYMENT_STATUS.INITIATED ? (
        <Message
          message="Please wait while we fetch payment status..."
          icon={<Loading />}
        />
      ) : paymentStatus === PAYMENT_STATUS.PROCESSING ? (
        <Message
          message="Please wait while we fetch payment status..."
          extra={
            <Typography variant="body1">
              This is taking longer than usual. Please wait... <br />
            </Typography>
          }
          icon={<Loading color="warning" />}
        />
      ) : paymentStatus === PAYMENT_STATUS.UNKNOWN ? (
        <Message
          message="We are unable to fetch payment status"
          extra={
            <>
              <Typography variant="body1" gutterBottom>
                If payment is successful, your account will be activated
                shortly. If not activated within 24 hours, please contact us.
              </Typography>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Go to <Link to={routes.home.path}>Home</Link>
              </Typography>
            </>
          }
          icon={<WarningIcon color="warning" sx={{ fontSize: 60 }} />}
        />
      ) : null}
    </Box>
  );
};

export default withAuth(Payment);

/* *
Initially, the status of the payment is set to PAYMENT_STATUS.INITIATED.
If backend says that payment is in progress, we still consider it as PAYMENT_STATUS.INITIATED before we try for 5 more times.
After 5 times, if payment is still in progress, we then consider it as PAYMENT_STATUS.PROCESSING and we show a warning to user saying that it is taking longer than usual.
After trying for 10 times, if payment is still in progress, we then consider it as PAYMENT_STATUS.UNKNOWN and we show a warning to user saying that we are unable to fetch payment status.

If at any time, backend says that payment is either success or failed, we set the status accordingly.
But in case of progress, we wait for some time before showing that to the user.
*/
