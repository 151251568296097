const Shipping = () => {
  return (
    <div className="policy">
      <h1>Shipping</h1>
      <p>Our services are online and therefore the delivery is instant.</p>
    </div>
  );
};

export default Shipping;
