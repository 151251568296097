const Terms = () => {
  return (
    <div className="policy">
      <h1>Terms</h1>
      <p>
        This application is operated by EasyHiring Infotech throughout the
        website, the terms “we”, “us” and “our” refer to EasyHiring offers this
        application, including all information, tools and services available
        from this website to you, the user, conditioned upon your acceptance of
        all terms, conditions, policies and notices stated here. By visiting our
        website and/ or purchasing something from us, you engage in our
        “Service” and agree to be bound by the following terms and conditions
        (“Terms of Service”, “Terms”), including those additional terms and
        conditions and policies referenced herein and/or available by hyperlink.
      </p>
      <p>
        These Terms of Service apply to all users of the website, including
        without limitation users who are browsers, vendors, customers,
        merchants, and/ or contributors of content. Please read these Terms of
        Service carefully before accessing or using our website. By accessing or
        using any part of the website, you agree to be bound by these Terms of
        Service. If you do not agree to all the terms and conditions of this
        agreement, then you may not access the website or use any services. If
        these Terms of Service are considered an offer, acceptance is expressly
        limited to these Terms of Service. Any new features or tools which are
        added to the current website shall also be subject to the Terms of
        Service. You can review the most current version of the Terms of Service
        at any time on this page. We reserve the right to update, change or
        replace any part of these Terms of Service by posting updates and/or
        changes to our website. It is your responsibility to check this page
        periodically for changes. Your continued use of or access to the website
        following the posting of any changes constitutes acceptance of those
        changes.
      </p>
      <p>
        By agreeing to these Terms of Service, you represent that you are at
        least the age of majority in your state or province of residence, or
        that you are the age of majority in your state or province of residence
        and you have given us your consent to allow any of your minor dependents
        to use this website. You may not use our products for any illegal or
        unauthorized purpose nor may you, in the use of the Service, violate any
        laws in your jurisdiction (including but not limited to copyright laws).
        You must not transmit any worms or viruses or any code of a destructive
        nature. Breach or violation of any of the Terms will result in an
        immediate termination of your Services.
      </p>
      <p>
        We reserve the right to refuse service to anyone for any reason at any
        time. You understand that your content (not including credit card
        information), may be transferred unencrypted and involve (a)
        transmissions over various networks; and (b) changes to conform and
        adapt to technical requirements of connecting networks or devices.
        Credit card information is always encrypted during transfer over
        networks. You agree not to reproduce, duplicate, copy, sell, resell or
        exploit any portion of the Service, use of the Service, or access to the
        Service or any contact on the website through which the service is
        provided, without express written permission by us. The headings used in
        this agreement are included for convenience only and will not limit or
        otherwise affect these Terms. We are not responsible if information made
        available on this website is not accurate, complete or current. The
        material on this website is provided for general information only and
        should not be relied upon or used as the sole basis for making decisions
        without consulting primary, more accurate, more complete or more timely
        sources of information. Any reliance on the material on this website is
        at your own risk. This website may contain certain historical
        information. Historical information, necessarily, is not current and is
        provided for your reference only. We reserve the right to modify the
        contents of this website at any time, but we have no obligation to
        update any information on our website. You agree that it is your
        responsibility to monitor changes to our website.
      </p>
      <p>
        {" "}
        Prices for our products are subject to change without notice. We reserve
        the right at any time to modify or discontinue the Service (or any part
        or content thereof) without notice at any time. We shall not be liable
        to you or to any third-party for any modification, price change,
        suspension or discontinuance of the Service. Certain products or
        services may be available exclusively online through the website. These
        products or services may have limited quantities and are subject to
        refund only according to our Refund Policy. We have made every effort to
        display as accurately as possible the colors and images of our products
        that appear at the website. We • Certain products or services may be
        available exclusively online through the website. These products or
        services may have limited quantities and are subject to refund only
        according to our Refund Policy. We have made every effort to display as
        accurately as possible the colors and images of our products that appear
        at the website. We cannot guarantee that your computer monitor's display
        of any color will be accurate. We reserve the right, but are not
        obligated, to limit the sales of our products or Services to any person,
        geographic region or jurisdiction. We may exercise this right on a
        case-by-case basis. We reserve the right to limit the quantities of any
        products or services that we offer. All descriptions of products or
        product pricing are subject to change at anytime without notice, at the
        sole discretion of us. We reserve the right to discontinue any product
        at any time. Any offer for any product or service made on this website
        is void where prohibited. We do not warrant that the quality of any
        products, services, information, or other material purchased or obtained
        by you will meet your expectations, or that any errors in the Service
        will be corrected.
      </p>
      <p>
        {" "}
        We reserve the right to refuse any order you place with us. We may, in
        our sole discretion, limit or cancel quantities purchased per person,
        per household or per order. These restrictions may include orders placed
        by or under the same customer account, the same credit card, and/or
        orders that use the same billing address. In the event that we make a
        change to or cancel an order, we may attempt to notify you by contacting
        the e- mail and/or billing address/phone number provided at the time the
        order was made. We reserve the right to limit or prohibit orders that,
        in our sole judgment, appear to be placed by dealers, resellers or
        distributors. You agree to provide current, complete and accurate
        purchase and account information for all purchases made at our website.
        You agree to promptly update your account and other information,
        including your email address and credit card numbers and expiration
        dates, so that we can complete your transactions and contact you as
        needed. For more detail, please review our Refund Policy. We may provide
        you with access to third-party tools over which we neither monitor nor
        have any control nor input. You acknowledge and agree that we provide
        access to such tools ”as is” and “as available” without any warranties,
        representations or conditions of any kind and without any endorsement.
        We shall have no liability whatsoever arising from or relating to your
        use of optional third-party tools. Any use by you of optional tools
        offered through the website is entirely at your own risk and discretion
        and you should ensure that you are familiar with and approve of the
        terms on which tools are provided by the relevant third- party
        provider(s). We may also, in the future, offer new services and/or
        features through the website (including, the release of new tools and
        resources). Such new features and/or services shall also be subject to
        these Terms of Service.
      </p>
      <p>
        {" "}
        Certain content, products and services available via our Service may
        include materials from third-parties. Third-party links on this website
        may direct you to third-party apps/websites that are not affiliated with
        us. We are not responsible for examining or evaluating the content or
        accuracy and we do not warrant and will not have any liability or
        responsibility for any third-party materials or apps/websites, or for
        any other materials, products, or services of third-parties. We are not
        liable for any harm or damages related to the purchase or use of goods,
        services, resources, content, or any other transactions made in
        connection with any third- party apps/websites. Please review carefully
        the third- party’s policies and practices and make sure you understand
        them before you engage in any transaction. Complaints, claims, concerns,
        or questions regarding third-party products should be directed to the
        third-party. We do not guarantee, represent or warrant that your use of
        our service will be uninterrupted, timely, secure or error-free. We do
        not warrant that the results that may be obtained from the use of the
        service will be accurate or reliable. You agree that from time to time
        we may remove the service for indefinite periods of time or cancel the
        service at any time, without notice to you. You expressly agree that
        your use of, or inability to use, the service is at your sole risk. The
        service and all products and services delivered to you through the
        service are (except as expressly stated by us) provided ‘as is’ and ‘as
        available’ for your use, without any representation, warranties or
        conditions of any kind, either express or implied, including all implied
        warranties or conditions of merchantability, merchantable quality,
        fitness for a particular purpose, durability, title, and non-
        infringement. In no case shall we, our directors, officers, employees,
        affiliates, agents, contractors, interns, suppliers, service providers
        or licensors be liable for any injury, loss, claim, or any direct,
        indirect, incidental, punitive, special, or consequential damages of any
        kind, including, without limitation lost profits, lost revenue, lost
        savings, loss of data, replacement costs, or any similar damages,
        whether based in contract, tort (including negligence), strict liability
        or otherwise, arising from your use of any of the service or any
        products procured using the service, or for any other claim related in
        any way to your use of the service or any product, including, but not
        limited to, any errors or omissions in any content, or any loss or
        damage of any kind incurred as a result of the use of the service or any
        content (or product) posted, transmitted, or otherwise made available
        via the service, even if advised of their possibility. Because some
        states or jurisdictions do not allow the exclusion or the limitation of
        liability for consequential or incidental damages, in such states or
        jurisdictions, our liability shall be limited to the maximum extent
        permitted by law.
      </p>
      <p>
        You can review the most current version of the Terms of Service at any
        time at this page. We reserve the right, at our sole discretion, to
        update, change or replace any part of these Terms of Service by posting
        updates and changes to our website. It is your responsibility to check
        our website periodically for changes. Your continued use of or access to
        our website or the Service following the posting of any changes to these
        Terms of Service constitutes acceptance of those changes
      </p>
      <p>
        Questions aboutthe Terms of Service should be sent to us at
        <span>
          {" "}
          <a
            class="font-medium text-blue-600 hover:underline"
            href="mailto:info@easyhiring.in"
          >
            info@easyhiring.in
          </a>
        </span>
      </p>
      <div class="empty"></div>
    </div>
  );
};

export default Terms;
