import { Box, Paper, Typography } from "@mui/material";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Message = ({
  message,
  hideBorder,
  icon,
  extra,
  redirectTime = 5,
  redirectTo,
}) => {
  const [redirectSeconds, setRedirectSeconds] = useState(redirectTime);
  const navigate = useNavigate();

  useEffect(() => {
    if (!redirectTo) {
      return;
    }

    const interval = setInterval(() => {
      setRedirectSeconds(redirectSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [redirectSeconds]);

  if (redirectTo && redirectSeconds <= 0) {
    navigate(redirectTo);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "90%",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          width: "50%",
          height: "20%",
          padding: "8%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          border: hideBorder ? "none" : "1px solid #e0e0e0",
        }}
        variant="outlined"
      >
        {icon ? icon : <AllInclusiveIcon fontSize="large" />}
        <Typography variant="h6" component="div">
          {message}
        </Typography>
        {redirectTo && (
          <Typography variant="body1" component="div">
            Redirecting in {redirectSeconds >= 0 ? redirectSeconds : 0} seconds.
            If not automatically redirected, click{" "}
            <Link to={redirectTo}>here</Link>.
          </Typography>
        )}
        {extra && <Box sx={{ height: "10%" }}>{extra}</Box>}
      </Paper>
    </Box>
  );
};

export default Message;
