import { Box, Paper } from "@mui/material";
import { useEffect } from "react";
import AboutUser from "../../components/Profile/AboutUser";
import Education from "../../components/Profile/Education";
import Experience from "../../components/Profile/Experience";
import Projects from "../../components/Profile/Projects";
import ProfileAvatar from "../../components/ProfileAvatar";
import withAuth from "../../hoc/withAuth";
import { USER_ROLE } from "../../utils/constants";
import UserSubscription from "../../components/Profile/UserSubscription";

const Profile = ({ user, fetchUserDetails }) => {
  useEffect(() => {
    if (!user) {
      fetchUserDetails(false);
    }
  }, []);
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        pt: 2,
        overflow: "scroll",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 200,
          height: 200,
          p: 3,
          position: "relative",
          borderRadius: 3,
          border: "1px solid #aaa",
        }}
      >
        <ProfileAvatar src={user?.avatar} />
      </Box>
      <Paper
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "80%",
          mb: 3,
          mt: 3,
          backgroundColor: "transparent",
        }}
      >
        <AboutUser />
        <UserSubscription subscription={user?.subscription} />
        {user?.role === USER_ROLE.student && (
          <>
            <Education />
            <Projects />
            <Experience />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default withAuth(Profile);
