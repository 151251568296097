import {
  Delete as DeleteIcon,
  FileUploadOutlined as UploadIcon,
} from "@mui/icons-material";
import { Avatar, Box, IconButton } from "@mui/material";
import { useState } from "react";
import { changeUserAvatar, removeUserAvatar } from "../../api/user";
import Loading from "../utils/loading";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const ProfileAvatar = ({ src }) => {
  const [loading, setLoading] = useState(false);
  const editAvatar = async (e) => {
    try {
      setLoading(true);
      const file = e.target.files[0];
      await changeUserAvatar(file);
    } finally {
      setLoading(false);
    }
  };
  const removeAvatar = async () => {
    try {
      setLoading(true);
      await removeUserAvatar();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Avatar sx={{ width: 200, height: 200 }}>
          <Loading />
        </Avatar>
      ) : (
        <Avatar
          sx={{ width: 200, height: 200 }}
          src={src + "?" + performance.now()} // to force reload
        />
      )}
      <Box sx={{ position: "absolute", right: 0, top: 0 }}>
        <IconButton component="label">
          <input hidden accept="image/*" type="file" onChange={editAvatar} />
          <UploadIcon />
        </IconButton>
        {src && (
          <IconButton onClick={removeAvatar}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default ProfileAvatar;
