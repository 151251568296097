import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router";
import { getCertificate } from "../../api/certificate";
import Loading from "../../components/utils/loading";
import Error from "../../components/utils/error";

const Certificate = () => {
  const [url, setUrl] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const { certificateId } = useParams();

  React.useEffect(() => {
    fetchCertificate();
  }, []);

  const fetchCertificate = async () => {
    try {
      setError(null);
      const res = await getCertificate(certificateId);
      setUrl(res.certificateUrl);
    } catch (err) {
      if (err?.response?.status === 404) {
        setError(err?.response?.data);
      } else {
        setError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        overflow: "scroll",
        flex: 1,
        p: 4,
        pt: 1,
      })}
    >
      <Box
        sx={(theme) => ({
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            m: 1,
          },
        })}
      >
        <Typography
          variant="button"
          sx={(theme) => ({
            fontWeight: 500,
            fontSize: "1.5rem",
            [theme.breakpoints.down("md")]: {
              fontSize: "1.2rem",
              fontWeight: 400,
            },
          })}
          gutterBottom
        >
          Certificate
        </Typography>
        {loading ? (
          <Loading />
        ) : error ? (
          <Error message={error.message} />
        ) : (
          <img
            src={url}
            alt="certificate"
            style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Certificate;
