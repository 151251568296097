import { Paper } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import { useLocation } from "react-router";
import PasswordResetForm from "../../components/PasswordResetForm";

export default function PasswordResetPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `linear-gradient(to bottom, ${grey[100]}, ${grey[300]})`,
          filter: "blur(10px)",
          position: "relative",
        }}
      ></Box>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <PasswordResetForm email={email} />
      </Paper>
    </>
  );
}
