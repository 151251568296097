import { Box, Button, Divider, Typography } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";

export default function Header({ title, editMode, setEditMode, onSubmit }) {
  return (
    <>
      <Box
        sx={{
          p: 1,
          pl: 2,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        {editMode ? (
          <Box>
            <Button variant="outlined" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <Button sx={{ ml: 1 }} variant="contained" onClick={onSubmit}>
              Save
            </Button>
          </Box>
        ) : setEditMode ? (
          <Button onClick={() => setEditMode(true)} startIcon={<EditIcon />}>
            Edit
          </Button>
        ) : null}
      </Box>
      <Divider />
    </>
  );
}
