import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { verifyAccountReq } from "../../api/login";
import { grey } from "@mui/material/colors";
import VerifyForm from "../../components/Verify/VerifyForm";
import withAuth from "../../hoc/withAuth";
import { Link } from "react-router-dom";

const VerifyPage = ({ user }) => {
  const onSubmit = async (otp) => {
    await verifyAccountReq({ email: user?.email, otp, mobile: user?.mobile });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `linear-gradient(to bottom, ${grey[100]}, ${grey[300]})`,
          filter: "blur(10px)",
          position: "relative",
        }}
      ></Box>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <VerifyForm
          email={user?.email}
          mobile={user?.mobile}
          onSubmit={onSubmit}
        />
        <Box
          sx={{
            p: 2,
            pb: 1,
          }}
        >
          <Typography variant="subtitle2" gutterBottom component="div">
            <Link to="/login">Not your account?</Link>
          </Typography>
        </Box>
      </Paper>
    </>
  );
};

export default withAuth(VerifyPage);
