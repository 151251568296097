import Message from "./message";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

export default function Error(props) {
  return (
    <Message
      message={props.message}
      icon={<WarningRoundedIcon color="error" />}
      {...props}
    />
  );
}
