import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { sendOtpReq } from "../../api/login.js";
import { changeUsername } from "../../api/user.js";
import { MAX_OTP_ATTEMPTS } from "../../constants.js";
import withAuth from "../../hoc/withAuth.js";
import { EmailInput, MobileInput } from "../utils/inputs.js";
import Popup from "../utils/popup.js";

const VerifyForm = ({ email, mobile, onSubmit, fetchUserDetails }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [otp, setOTP] = useState("");
  const [otpAttemptsLeft, setOtpAttemptsLeft] = useState(MAX_OTP_ATTEMPTS);
  const [changePopupOpen, setChangePopupOpen] = useState(false);

  const [updatedDetails, setUpdatedDetails] = useState(mobile || email);
  const [changeDetailError, setChangeDetailError] = useState("");

  useEffect(() => {
    sendOtp();
    setUpdatedDetails(mobile || email);
  }, [email, mobile]);

  const sendOtp = async () => {
    if (!email && !mobile) {
      return;
    }

    try {
      setLoading(true);
      await sendOtpReq({ mobile, email });
    } catch (err) {
      console.log(err);
      setError(err.response.data?.message || "Failed to send OTP");
    } finally {
      setLoading(false);
    }
  };

  const onResendOtpClick = () => {
    if (otpAttemptsLeft === 0) {
      return;
    }
    sendOtp();
    setOtpAttemptsLeft(otpAttemptsLeft - 1);
  };

  const verifyAccount = async () => {
    try {
      await onSubmit(otp);
    } catch (err) {
      console.log(err);
      setError(err.response.data?.message || "OTP verification failed");
    }
  };

  const showChangePopup = () => {
    setChangePopupOpen(true);
  };
  const closeChangePopup = () => {
    setChangePopupOpen(false);
  };
  const onDetailsChange = (e) => {
    setChangeDetailError("");
    setUpdatedDetails(e.target.value);
  };
  const changeDetails = async () => {
    if (mobile && !/^[0-9]{10}$/i.test(updatedDetails)) {
      setChangeDetailError("Invalid mobile number");
      return;
    } else if (
      !mobile &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(updatedDetails)
    ) {
      setChangeDetailError("Invalid email");
      return;
    }
    // API call
    setLoading(true);
    try {
      await changeUsername(
        mobile ? updatedDetails : null,
        email ? updatedDetails : null
      );
      await fetchUserDetails();
      closeChangePopup();
    } catch (err) {
      console.log(err);
      setChangeDetailError(err.response.data?.message || "Failed to change");
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
      }}
    >
      <Popup
        open={changePopupOpen}
        title={"Change " + (mobile ? "mobile" : "email")}
        content={
          <FormControl sx={{ mt: 2, mb: 2 }}>
            {mobile ? (
              <MobileInput
                mobile={updatedDetails}
                onChange={onDetailsChange}
                error={changeDetailError}
              />
            ) : (
              <EmailInput
                email={updatedDetails}
                onChange={onDetailsChange}
                error={changeDetailError}
              />
            )}
          </FormControl>
        }
        actions={[
          <LoadingButton
            loading={loading}
            disabled={updatedDetails === (mobile || email)}
            onClick={changeDetails}
          >
            Change
          </LoadingButton>,
        ]}
        onClose={closeChangePopup}
      />
      <Typography variant="h5" gutterBottom component="div">
        Verify Account
      </Typography>
      {error && (
        <Alert sx={{ width: "100%", m: 2, mt: 1 }} severity="error">
          <strong>{error}</strong>
        </Alert>
      )}

      <Typography variant="body1" gutterBottom component="div">
        We have sent an OTP to{" "}
        <span>{mobile ? <b>{mobile}</b> : <b>{email}</b>}</span> (
        <Link component="button" variant="body2" onClick={showChangePopup}>
          change
        </Link>
        ). Please enter the OTP below to verify your account.
      </Typography>
      <FormControl
        sx={{
          mt: 2,
          mb: 2,
        }}
      >
        <TextField
          id="reset-otp"
          value={otp}
          onChange={(e) => setOTP(e.target.value)}
          label="OTP"
          required
        />
        <Button
          onClick={onResendOtpClick}
          disabled={loading || otpAttemptsLeft === 0}
        >
          {`Resend OTP (${otpAttemptsLeft} ${
            otpAttemptsLeft === 1 ? "attempt" : "attempts"
          } left)`}
        </Button>
      </FormControl>

      <Box>
        <Button
          type="submit"
          size="large"
          variant="outlined"
          onClick={verifyAccount}
          disabled={loading || !otp}
          startIcon={loading && <CircularProgress size="1rem" />}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default withAuth(VerifyForm);
