import { postReq } from ".";
import { USER_ROLE } from "../utils/constants";

const checkForReturnTo = (user) => {
  const query = new URLSearchParams(window.location.search);
  const returnTo = query.get("returnTo");
  if (returnTo) {
    window.location = returnTo;
  } else {
    window.location =
      user.role === USER_ROLE.admin ||
      user.role === USER_ROLE.superadmin ||
      user.role === USER_ROLE.staffadmin
        ? process.env.REACT_APP_ADMIN_WEB
        : user.role === USER_ROLE.companyadmin
        ? process.env.REACT_APP_PATH_WEB
        : process.env.REACT_APP_SM_WEB;
  }
};

export const loginReq = async (username, password) => {
  let res = await postReq("/login", { username, password });
  const { token, user } = res;
  document.cookie = `token=${token};path=/;domain=eh.com`;
  localStorage.setItem("token", token);

  checkForReturnTo(user);

  return res;
};

export const logoutReq = async () => {
  await postReq("/logout");
};

export const registerReq = async (data) => {
  let res = await postReq("/register", data);
  const { token } = res;
  // document.cookie = `token=${token};path=/;domain=eh.com`;
  localStorage.setItem("token", token);

  const query = new URLSearchParams(window.location.search);
  const returnTo = query.get("returnTo");
  if (returnTo) {
    window.location = returnTo;
  } else {
    window.location.href = "/";
  }

  return res;
};

export const sendOtpReq = async ({ mobile, email }) => {
  let res = await postReq("/otp", { mobile, email });
  const { otp } = res;
  return otp;
};

export const passwordResetReq = async (email, otp, password) => {
  let res = await postReq("/reset-password", { email, otp, password });
  return res;
};

export const verifyAccountReq = async ({ otp, email, mobile }) => {
  const { user } = await postReq("/verify-account", { otp, email, mobile });
  checkForReturnTo(user);
};
