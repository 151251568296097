import { getReq, postReq, putReq } from ".";

export const getPlans = () => {
	return getReq(`/plans`, {}, false);
};

export const getPlan = (id) => {
	return getReq(`/plans/${id}`, {}, false);
};

export const createOrder = ({ planId, couponCode }) => {
	return postReq("/payments", { planId, couponCode }, true);
};

export const getPaymentStatus = (trans_id) => {
	return getReq("/payments/status?trans_id=" + trans_id, {}, true);
};

export const applyCoupon = ({ planId, coupon }) => {
	return postReq("/plans/coupon", { planId, coupon }, true);
};

export const getQuote = ({ planId, couponCode }) => {
	console.log({ planId, couponCode });
	return getReq("/payments/quote", { planId, couponCode }, true);
};
