import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  colors,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getPlans } from "../../api/plans";
import PlanCard from "../../components/Plans/PlanCard";
import Popup from "../../components/utils/popup";
import { FEATURES } from "../../constants";
import withAuth from "../../hoc/withAuth";
import { PAYMENT_STATUS } from "../../utils/constants";

const Plans = ({ user }) => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});

  useEffect(() => {
    getPlans().then((res) => {
      setPlans(res.plans);
    });
  }, []);

  console.log(user?.subscription);
  const hasSubscription =
    user?.subscription && dayjs(user.subscription.validTill).isAfter(dayjs());

  const query = new URLSearchParams(window.location.search);

  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        pt: 2,
        overflow: "scroll",
        backgroundColor: "white",
      }}
    >
      {query.get("payment") === PAYMENT_STATUS.FAILED && (
        <Alert severity="error">
          <AlertTitle>Payment Failed!</AlertTitle>
          Please try again after some time. If problem persists, please{" "}
          <a
            href={process.env.REACT_APP_CONTACT_LINK}
            target="_blank"
            rel="noreferrer"
          >
            contact us
          </a>
        </Alert>
      )}
      <Paper
        variant="outlined"
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          width: "80%",
          mb: 2,
          flex: 1,
          alignItems: "center",
          backgroundColor: colors.blue[100],
          [theme.breakpoints.down("md")]: {
            width: "90%",
          },
        })}
      >
        <Typography variant="h4" sx={{ p: 2 }}>
          Plans
        </Typography>

        {hasSubscription ? (
          <Box
            sx={{
              mt: "auto",
              mb: "auto",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ m: 2, color: colors.deepOrange[900] }}
              variant="h6"
            >
              You are already subscribed to a plan! Your subscription is valid
              till {dayjs(user.subscription.validTill).format("DD MMM YYYY")}
            </Typography>

            <Typography sx={{ m: 2 }} variant="h5">
              Enjoy learning!
            </Typography>
          </Box>
        ) : (
          // Table showing features vs plans with tick or cross

          <TableContainer component={Paper} sx={{ width: "95%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Features</TableCell>
                  {plans.map((plan) => (
                    <TableCell align="center">
                      <Typography variant="h5" fontWeight={500}>
                        {plan.name}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {FEATURES.map((feature) => (
                  <TableRow key={feature.value}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body1" fontWeight={500}>
                        {feature.name}
                      </Typography>
                      <Typography variant="body2">
                        {feature.description}
                      </Typography>
                    </TableCell>
                    {plans.map((plan) => (
                      <TableCell align="center">
                        {plan.features.includes(feature.value) ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" fontWeight={500}>
                      Price
                    </Typography>
                  </TableCell>
                  {plans.map((plan) => (
                    <TableCell align="center" sx={{ verticalAlign: "bottom" }}>
                      <Typography
                        variant="h6"
                        fontWeight={500}
                        color="black"
                        sx={
                          plan.discountedAmount
                            ? {
                                textDecoration: "line-through",
                                color: "grey",
                                fontSize: "1rem",
                              }
                            : {}
                        }
                      >
                        &#8377; {plan.amount}
                      </Typography>
                      {plan.discountedAmount && (
                        <Typography variant="h6" fontWeight={500} color="black">
                          &#8377; {plan.discountedAmount}
                        </Typography>
                      )}
                      <Typography variant="body2">
                        {plan.intervalCount}{" "}
                        {plan.intervalCount < 2 ? plan.unit : plan.unit + "s"}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => setSelectedPlan(plan)}
                      >
                        Subscribe
                      </Button>
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <Popup
        open={selectedPlan?._id}
        title={`Subscribe to ${selectedPlan?.name} plan`}
        content={<PlanCard plan={selectedPlan} />}
        onClose={() => setSelectedPlan({})}
      />
    </Box>
  );
};

export default withAuth(Plans);
