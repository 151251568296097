import Payment from "../components/Payment";
import Certificate from "../pages/Certificate";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Logout from "../pages/Login/logout";
import PasswordResetPage from "../pages/PasswordReset";
import Plans from "../pages/Plans";
import Profile from "../pages/Profile";
import Register from "../pages/Register";
import VerifyPage from "../pages/Register/verify";
import CancellationPolicy from "../pages/misc/CancellationPolicy";
import PrivacyPolicy from "../pages/misc/PrivacyPolicy";
import Refund from "../pages/misc/Refund";
import Return from "../pages/misc/Return";
import Shipping from "../pages/misc/Shipping";
import Terms from "../pages/misc/Terms";

const routes = {
  home: {
    title: "Home",
    path: "/",
    component: <Home />,
  },
  login: {
    title: "Login",
    path: "/login",
    component: <Login />,
  },
  logout: {
    title: "Logout",
    path: "/logout",
    component: <Logout />,
  },
  register: {
    title: "Register",
    path: "/register",
    component: <Register />,
  },
  verify: {
    title: "Verify",
    path: "/verify",
    component: <VerifyPage />,
  },
  resetPassword: {
    title: "Reset Password",
    path: "/reset-password",
    component: <PasswordResetPage />,
  },
  profile: {
    title: "Profile",
    path: "/profile",
    component: <Profile />,
  },
  plans: {
    title: "Plans",
    path: "/plans",
    component: <Plans />,
  },
  pricing: {
    title: "Pricing",
    path: "/pricing",
    component: <Plans />,
  },
  payment: {
    title: "Payment",
    path: "/payment",
    component: <Payment />,
  },
  certificate: {
    title: "Certificate",
    _path: "/certificate",
    get path() {
      return `${this._path}/:certificateId`;
    },
    component: <Certificate />,
  },
  privacy: {
    title: "Privacy",
    path: "/privacy",
    component: <PrivacyPolicy />,
  },
  cancellation: {
    title: "Cancellation",
    path: "/cancellation",
    component: <CancellationPolicy />,
  },
  terms: {
    title: "Terms",
    path: "/terms",
    component: <Terms />,
  },
  refund: {
    title: "Refund",
    path: "/refund",
    component: <Refund />,
  },
  return: {
    title: "Return",
    path: "/return",
    component: <Return />,
  },
  shipping: {
    title: "Shipping",
    path: "/shipping",
    component: <Shipping />,
  },
};

export default routes;
