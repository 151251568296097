import Message from "./message";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function Success(props) {
  return (
    <Message
      message={props.message}
      icon={<CheckCircleIcon color="success" />}
      {...props}
    />
  );
}
