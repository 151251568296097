import React from "react";
import { ButtonBase, Typography } from "@mui/material";
import routes from "./routes";
import { useNavigate } from "react-router-dom";

const Logo = ({ disableClick }) => {
  const navigate = useNavigate();

  return (
    <ButtonBase
      disabled={disableClick}
      sx={{
        padding: 1,
        paddingLeft: 2,
        paddingRight: 2,
        borderRadius: 1.5,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        ml: disableClick ? "auto" : 0,
        mr: disableClick ? "auto" : 0,
      }}
      onClick={() => navigate(routes.home.path)}
    >
      <img src="/logo192.png" alt="logo" height={20} width={20} />
      <Typography variant="h6" component="div" sx={{ ml: 1.2 }}>
        Easy Hiring
      </Typography>
    </ButtonBase>
  );
};

export default Logo;
