import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import withAuth from "../hoc/withAuth";
import routes from "./routes";
import { useLocation, useNavigate } from "react-router";

const Account = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const profileRef = useRef(null);
  const [profileMenuIsVisible, setProfileMenuIsVisible] = useState(false);

  const isNotRegisterPage = window.location.pathname !== routes.register.path;
  const isNotLoginPage = window.location.pathname !== routes.login.path;

  const showProfileMenu = () => setProfileMenuIsVisible(true);
  const hideProfileMenu = () => setProfileMenuIsVisible(false);

  const goToProfilePage = () => {
    hideProfileMenu();
    navigate(routes.profile.path);
  };
  const goToLoginPage = () => {
    navigate(routes.login.path + location.search);
  };
  const goToRegisterPage = () => {
    navigate(routes.register.path + location.search);
  };

  const performLogout = () => {
    hideProfileMenu();
    sessionStorage.clear();
    navigate(routes.logout.path);
  };

  return (
    <div style={{ marginLeft: "auto" }}>
      {isAuthenticated ? (
        <>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={showProfileMenu}
            ref={profileRef}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={profileRef}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={profileMenuIsVisible}
            onClose={hideProfileMenu}
          >
            <MenuItem onClick={goToProfilePage}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={performLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {isNotRegisterPage && (
            <Button color="inherit" onClick={goToRegisterPage}>
              Register
            </Button>
          )}
          {isNotRegisterPage && isNotLoginPage && (
            <Divider orientation="vertical" variant="middle" flexItem />
          )}
          {isNotLoginPage && (
            <Button color="inherit" onClick={goToLoginPage}>
              Login
            </Button>
          )}
        </Box>
      )}
    </div>
  );
};

export default withAuth(Account);
