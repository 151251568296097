export const validateEmail = (value) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
};
export const validateMobile = (value) => {
  return /^[0-9]{10}$/i.test(value);
};

// should be in sync with backend
export const passwordConditions = [
  {
    title: "Must be between 8 and 24 characters long",
    condition: (password) => password.length >= 8 && password.length <= 24,
  },
  {
    title: "Must contain at least one uppercase letter",
    condition: (password) => /[A-Z]/.test(password),
  },
  {
    title: "Must contain at least one lowercase letter",
    condition: (password) => /[a-z]/.test(password),
  },
  {
    title: "Must contain at least one number",
    condition: (password) => /[0-9]/.test(password),
  },
  {
    title: "Must contain at least one special character",
    condition: (password) => /[!@#$%^&*]/.test(password),
  },
];
export const validatePassword = (value) => {
  return passwordConditions.every((condition) => condition.condition(value));
};

export const isCompanyEmail = (email) => {
  // Regular expression to exclude common personal email providers
  var personalEmailPattern =
    /@(gmail|yahoo|hotmail|outlook|aol|icloud|mail|live|inbox|zoho|protonmail|yandex|gmx|me)\./i;

  // Test if the email matches the pattern
  return !personalEmailPattern.test(email);
};

export const validateUrl = (value) => {
  var urlPattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlPattern.test(value);
};
