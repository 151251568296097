import React, { useEffect } from "react";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";

import withAuth from "../../hoc/withAuth";
import { updateEducationDetails } from "../../api/user";
import Duration from "./Duration";
import Header from "./Header";

const EducationDetails = ({ educations }) => {
  console.log(educations);
  if (!educations || !educations.length) {
    return (
      <Typography sx={{ p: 2 }} variant="body1">
        No education details
      </Typography>
    );
  }

  return (
    <List>
      {educations.map((education) => (
        <ListItem key={education._id}>
          <ListItemText
            primary={education.degree}
            secondary={education.college}
          />
        </ListItem>
      ))}
    </List>
  );
};

const EducationForm = ({ editedEducations, onChange }) => {
  const onValueChange = (index, education) => {
    const newEducations = [...editedEducations];
    newEducations[index] = education;
    onChange(newEducations);
  };

  const addNewEducation = () => {
    const newEducations = [...editedEducations, {}];
    onChange(newEducations);
  };
  const removeItem = (index) => {
    const newEducations = [...editedEducations];
    newEducations.splice(index, 1);
    onChange(newEducations);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {editedEducations.map((education, index) => (
        <>
          <Paper
            key={education._id}
            variant="outlined"
            sx={{ display: "flex", flexDirection: "row", p: 2, m: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <TextField
                label={"Degree"}
                value={education.degree}
                onChange={(e) =>
                  onValueChange(index, { ...education, degree: e.target.value })
                }
                sx={{ m: 1, ml: 0, mr: 0 }}
              />
              <TextField
                label={"College"}
                value={education.college}
                onChange={(e) =>
                  onValueChange(index, {
                    ...education,
                    college: e.target.value,
                  })
                }
                sx={{ m: 1, ml: 0, mr: 0 }}
              />
              <Duration
                startDate={education.startDate}
                endDate={education.endDate}
                onChange={(startDate, endDate) =>
                  onValueChange(index, {
                    ...education,
                    startDate,
                    endDate,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                ml: 2,
              }}
            >
              <IconButton onClick={() => removeItem(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Paper>
          <Divider />
        </>
      ))}
      <Button onClick={addNewEducation}>Add New</Button>
    </Box>
  );
};

const Education = ({ user }) => {
  const [editMode, setEditMode] = React.useState(false);
  const [educations, setEducations] = React.useState([]);
  const [editedEducations, setEditedEducations] = React.useState([]);

  useEffect(() => {
    const educations = user?.education || [];
    setEducations(educations);
    setEditedEducations(educations);
  }, [user]);

  const onSubmit = async () => {
    try {
      await updateEducationDetails(editedEducations);
      setEducations(editedEducations);
      setEditedEducations(editedEducations);
      setEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onCancel = () => {
    setEditMode(false);
    setEditedEducations(educations);
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        m: 1,
      }}
    >
      <Header
        title="Education"
        editMode={editMode}
        setEditMode={setEditMode}
        onSubmit={onSubmit}
      />
      {editMode ? (
        <EducationForm
          editedEducations={editedEducations}
          onChange={setEditedEducations}
        />
      ) : (
        <EducationDetails educations={educations} />
      )}
    </Paper>
  );
};

export default withAuth(Education);
