const CancellationPolciy = () => {
  return (
    <div className="policy">
      <h1>Cancellation Policy</h1>
      <p>
        At EasyHiring, we understand that plans may change, and sometimes it
        becomes necessary to cancel or reschedule appointments or services. To
        ensure clarity and transparency for both our customers and our team, we
        have established the following cancellation policy:
      </p>
      <h3>1. Cancellation Window:</h3>
      <p>
        Customers are allowed to cancel their service without any penalty if
        done within 7 days from the date of buying. Any cancellations made
        beyond this period will be subject to the terms mentioned below.
      </p>
      <h3>2. Cancellation Fees:</h3>
      For cancellations made beyond the cancellation window, the following fees
      will apply: - 5% of the total service cost for cancellations made within
      24 hours of the scheduled appointment. - 20% of the total service cost
      after 24 hours. 100% of the total service cost after 7 days.
      <h3>3. Refunds:</h3>
      <p>
        Refunds for cancelled appointments will be processed in accordance with
        our refund policy, which states that refunds will be issued within 3-5
        business days from the date of cancellation. Please note that refunds
        may take longer to process depending on the payment method used.
      </p>
      <h3>Changes to the Cancellation Policy:</h3>
      <p>
        EasyHiring reserves the right to modify or update this cancellation
        policy as needed. Any changes to the policy will be communicated to our
        customers through our website or other relevant channels. We aim to
        provide exceptional service and maintain a fair and consistent
        cancellation policy for all our customers. We appreciate your
        understanding and cooperation in adhering to this policy. If you have
        any questions or require further clarification, please feel free to
        reach out to our customer support team at{" "}
        <a href="mailto:support@easyhiring.in">support@easyhiring.in</a>.
      </p>
      <div class="empty"></div>
    </div>
  );
};

export default CancellationPolciy;
