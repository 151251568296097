import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { passwordConditions, validatePassword } from "../../utils/validations";

export const MobileInput = ({ mobile, onChange, error }) => {
  return (
    <TextField
      id="mobile-input"
      value={mobile}
      onChange={onChange}
      label="Mobile Number"
      type="number"
      required
      error={Boolean(error)}
      helperText={
        (error ? `${error}. ` : "") + "Please enter 10 digit mobile number"
      }
      InputProps={{
        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
      }}
    />
  );
};

export const EmailInput = ({ email, onChange, error }) => {
  return (
    <TextField
      id="email-innput"
      value={email}
      onChange={onChange}
      label="E-mail"
      type="email"
      required
      error={Boolean(error)}
      helperText={error}
    />
  );
};

export const PasswordInput = ({
  label,
  password,
  onChange,
  helperText,
  error,
  showConditions,
  onEnter,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onPasswordChange = (event) => {
    onChange(event.target.value, validatePassword(event.target.value));
  };

  return (
    <>
      <TextField
        value={password}
        onChange={onPasswordChange}
        type={showPassword ? "text" : "password"}
        label={label || "Password"}
        error={error}
        helperText={helperText}
        required
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onEnter?.();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showConditions && (
        <Stack>
          {passwordConditions.map((condition, index) => (
            <Alert
              key={index}
              sx={{ pt: 0, pb: 0, borderRadius: 0 }}
              severity={condition.condition(password) ? "success" : "error"}
            >
              {condition.title}
            </Alert>
          ))}
        </Stack>
      )}
    </>
  );
};
