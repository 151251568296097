import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Duration = ({ startDate, endDate, onChange }) => {
  const [errors, setErrors] = React.useState({});

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Typography variant="body1" sx={{ m: 1, ml: 0, mr: 0 }}>
          Duration:
        </Typography>
        <DatePicker
          disableFuture
          label="Start"
          openTo="year"
          views={["year", "month", "day"]}
          value={startDate}
          onChange={(newValue) => {
            if (newValue > endDate) {
              setErrors({
                ...errors,
                startDate: "Start date cannot be after end date",
              });
            } else {
              setErrors({
                ...errors,
                startDate: null,
                endDate: null,
              });
            }
            onChange(newValue, endDate);
          }}
          sx={{ m: 1, ml: 0, mr: 0 }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors.startDate)}
              helperText={errors.startDate}
            />
          )}
        />
        <DatePicker
          disableFuture
          label="End"
          openTo="year"
          views={["year", "month", "day"]}
          value={endDate}
          onChange={(newValue) => {
            if (newValue < startDate) {
              setErrors({
                ...errors,
                endDate: "End date cannot be before start date",
              });
            } else {
              setErrors({
                ...errors,
                startDate: null,
                endDate: null,
              });
            }
            onChange(startDate, newValue);
          }}
          sx={{ m: 1, ml: 0, mr: 0 }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(errors.endDate)}
              helperText={errors.endDate}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default Duration;
