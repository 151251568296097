import React from "react";
import { Button, CardContent, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export default function UserSubscription({ subscription }) {
  const navigate = useNavigate();
  const goToPlans = () => {
    navigate("/plans");
  };
  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        m: 1,
      }}
    >
      <Header title="Plan" />
      {!subscription || dayjs(subscription.validTill).isBefore(dayjs()) ? (
        <>
          <Typography sx={{ p: 2 }} variant="body1">
            You don't have any active subscription
          </Typography>
          <Button variant="contained" color="primary" onClick={goToPlans}>
            Buy Now
          </Button>
        </>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
          {subscription.plan && (
            <Typography component="div" variant="h5">
              {subscription.plan.name}
            </Typography>
          )}
          <Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Valid till: {dayjs(subscription.validTill).format("DD MMM YYYY")}
            </Typography>
          </Box>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {subscription?.plan?.description}
          </Typography>
        </Box>
      )}
    </Paper>
  );
}
