import {
  Alert,
  Box,
  Button,
  FormControl,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { passwordResetReq, sendOtpReq } from "../../api/login.js";
import { MAX_OTP_ATTEMPTS } from "../../constants";
import routes from "../../layout/routes";
import { PasswordInput } from "../utils/inputs";
import styles from "./styles.js";

export default function PasswordResetForm({ email: defaultEmail = "" }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(defaultEmail);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({ email: null });
  const [activeStep, setActiveStep] = useState(0);
  const [otpAttemptsLeft, setOtpAttemptsLeft] = useState(MAX_OTP_ATTEMPTS);

  const navigate = useNavigate();

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const backStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const sendOtp = async () => {
    setErrors({});
    try {
      setLoading(true);
      await sendOtpReq({ email });
      setActiveStep(1);
    } catch (err) {
      console.log(err);
      setErrors({
        message: err.response.data?.message || "Failed to send email",
      });
    } finally {
      setLoading(false);
    }
  };
  const onSendOtpClick = async () => {
    setErrors({ ...errors, email: null });
    if (!validateEmail(email)) {
      setErrors({ ...errors, email: "Invalid email" });
      return;
    }
    sendOtp();
    setOtpAttemptsLeft(MAX_OTP_ATTEMPTS);
  };
  const onResendOtpClick = () => {
    if (otpAttemptsLeft === 0) {
      return;
    }
    sendOtp();
    setOtpAttemptsLeft(otpAttemptsLeft - 1);
  };
  useEffect(() => {
    if (otpAttemptsLeft === 0) {
      setErrors({ ...errors, resendOtp: "No more attempts left" });
      return;
    }
  }, [otpAttemptsLeft]);

  const onSubmitClick = async () => {
    setErrors({});
    if (!validateEmail(email)) {
      setErrors({ email: "Invalid email" });
      return;
    }
    try {
      setLoading(true);
      await passwordResetReq(email, otp, password);
      setActiveStep(2);
    } catch (err) {
      console.log(err);
      setErrors({
        ...errors,
        message: err.response.data?.message || "Password reset failed",
      });
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (value) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
  };

  const onEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };
  const onOtpChange = (e) => {
    const { value } = e.target;
    setOtp(value);
  };
  const onPasswordChange = (value, meetsConditions) => {
    const newErrors = { ...errors };
    newErrors.confirmPassword =
      confirmPassword && value !== confirmPassword
        ? "Password does not match"
        : null;
    newErrors.password = meetsConditions ? null : "Password is too weak";
    setErrors(newErrors);
    setPassword(value);
  };
  const onConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    if (value && value !== password) {
      setErrors({ ...errors, confirmPassword: "Password does not match" });
    } else {
      setErrors({ ...errors, confirmPassword: null });
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h4" gutterBottom component="div" sx={styles.title}>
        Reset Password
      </Typography>
      {errors.message && (
        <Alert sx={{ marginBottom: 2 }} severity="error">
          {errors.message}
        </Alert>
      )}
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Enter your email</StepLabel>
          <StepContent>
            <FormControl sx={styles.formItem}>
              <TextField
                id="reset-email"
                value={email}
                onChange={onEmailChange}
                label="E-mail"
                type="email"
                required
                error={errors.email}
                helperText={errors.email}
              />
            </FormControl>
            <Box sx={{ mb: 2 }}>
              <Button
                variant="outlined"
                onClick={onSendOtpClick}
                sx={styles.submitButton}
                disabled={loading || validateEmail(email) === false}
              >
                Send OTP
              </Button>
            </Box>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Enter OTP and new password</StepLabel>
          <StepContent>
            <Alert severity="info">
              An OTP will be received on your mail (if registered with us).
            </Alert>

            <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
              <FormControl sx={styles.formItem}>
                <TextField
                  id="reset-otp"
                  value={otp}
                  onChange={onOtpChange}
                  label="OTP"
                  required
                  error={errors.otp}
                />
                <Button onClick={onResendOtpClick} disabled={errors.resendOtp}>
                  {errors.resendOtp ||
                    `Resend OTP (${otpAttemptsLeft} ${
                      otpAttemptsLeft === 1 ? "attempt" : "attempts"
                    } left)`}
                </Button>
              </FormControl>

              <FormControl sx={styles.formItem}>
                <PasswordInput
                  password={password}
                  onChange={onPasswordChange}
                  showConditions
                />
              </FormControl>

              <FormControl sx={styles.formItem}>
                <PasswordInput
                  label="Confirm Password"
                  password={confirmPassword}
                  onChange={onConfirmPasswordChange}
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword}
                />
              </FormControl>
            </Box>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button disabled={activeStep === 0} onClick={backStep}>
                  Back
                </Button>
                {activeStep === 1 && (
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={onSubmitClick}
                    sx={styles.submitButton}
                    disabled={
                      loading ||
                      errors.password ||
                      errors.confirmPassword ||
                      !otp ||
                      !password ||
                      !confirmPassword
                    }
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Box>
          </StepContent>
        </Step>
      </Stepper>

      {activeStep === 2 && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Password reset is successful. Login now.</Typography>
          <Button
            onClick={() => navigate(routes.login.path)}
            sx={{ mt: 1, mr: 1 }}
          >
            Login
          </Button>
        </Paper>
      )}
    </Box>
  );
}
