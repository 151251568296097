import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_AUTH_BACKEND_URL;

const redirect = (url, returnTo) => {
  if (!returnTo) {
    returnTo = window.location.href;
  }
  window.location = url + "?returnTo=" + returnTo;
};

const handleError = (error) => {
  if (error.response?.status === 403) {
    console.log(error.response.data);
    redirect(error.response.data.redirectUrl);
  }
  throw error;
};

export const postReq = async (url, body, auth) => {
  const headers = { "Content-Type": "application/json" };
  try {
    let response = await axios.post(url, body, { headers });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
export const postFileReq = async (url, file, auth) => {
  const headers = { "Content-Type": "multipart/form-data" };
  const formData = new FormData();
  formData.append("avatar", file);
  try {
    let response = await axios.post(url, formData, { headers });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const uploadToBucket = async (
  url,
  file,
  filetype = "application/octet-stream"
) => {
  const headers = {
    "Content-Type": filetype,
    "Access-Control-Allow-Origin": "*",
  };
  try {
    console.log(url);
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: file,
    });
    console.log(response);
    return response;
  } catch (error) {
    console.error({ error });
    throw error;
  }
};

export const getReq = async (url, params, auth, ignore = false) => {
  const headers = { "Content-Type": "application/json" };
  // const params = new URLSearchParams(queryParams);
  try {
    let response = await axios.get(url, { params, headers });
    return response.data;
  } catch (error) {
    if (ignore) {
      throw error;
    } else {
      handleError(error);
    }
  }
};

export const putReq = async (url, body, auth) => {
  const headers = { "Content-Type": "application/json" };
  try {
    let response = await axios.put(url, body, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      console.log(error.response.data);
    } else {
      handleError(error);
    }
  }
};

export const delReq = async (url, body, auth) => {
  const headers = { "Content-Type": "application/json" };
  try {
    let response = await axios.delete(url, { headers });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
